.setup-payment-method {
  max-width: 800px;
}

.top-up-balance {
  max-width: 400px;
}

.project-balance {
  max-width: 400px;
}

.payment-method-brand-logo {
  width: 20px;
}

.automatic-recharge {
  max-width: 400px;
}

